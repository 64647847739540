import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isAfter } from 'date-fns';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';

const isNil = (x: any): boolean => {
    if (x === null || x === undefined || x === '' || (typeof x === 'object' && Object.keys(x).length === 0) || (Array.isArray(x) && x.length === 0)) {
        return true;
    } else {
        return false;
    }
};

const validCv = (s: string) => {
    return isNil(s) ? false : s.indexOf('_Temporary') !== -1 ? false : true;
};

/**
 *
 *
 * @export
 * @class ApplicationService
 */
@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    public enrollmentSubject = new Subject<any>();

    setScrollApplicationChainEnrolledSubject: any = new BehaviorSubject<boolean>(false);
    getScrollApplicationChainEnrolledSubject = (): Observable<boolean> => this.setScrollApplicationChainEnrolledSubject.asObservable();

    constructor(private http: HttpClient) {}

    enrollmentChecker(): Observable<any> {
        return this.enrollmentSubject.asObservable();
    }
    // ------------------------------------------------------------------------------------------------------------- //

    /**
     * @description creates new application
     */
    newApplication = (data: any, token?: string): Observable<any> => {
        console.log('newApplication', data);
        return this.http.post(`${environment.API}/applications/newapplication`, data);
    };
    // ------------------------------------------------------------------------------------------------------------- //

    /**
     * @description list all active user applications
     */
    applicationList = (userid: string): Observable<any> => {
        return this.http.get(`${environment.API}/applications/${userid}/myapplicationslist`);
    };
    // ------------------------------------------------------------------------------------------------------------- //

    // -----------------------------------------------------------------------------------------------------------------//

    /**
     * @description lets the user view their signed clinical agreements
     * @param applicationid current application id
     * @method GET
     */
    getViewClinicalAgreement = (applicationid: string, userid: string): Observable<any> => {
        return this.http.get(`${environment.API}/applications/${applicationid}/viewclinicalagreement?userid=${userid}`);
    };

    // ------------------------------------------------------------------------------------------------------------- //

    /**
     *
     * @param applicationid id of application you want to cancel
     * @description cancel application
     * @method PUT
     */
    cancelApplication = (applicationid: string): Observable<any> => {
        return this.http.put(`${environment.API}/applications/${applicationid}/cancel`, {});
    };
    // ------------------------------------------------------------------------------------------------------------- //

    /**
     *
     * @param applicationid current application
     * @param data object containing housing information
     * @method PUT
     * @description submit housing information
     */
    submitHousing = (applicationid: string, data: object): Observable<any> => {
        return this.http.put(`${environment.API}/applications/${applicationid}/housinginfo`, data);
    };
    // ------------------------------------------------------------------------------------------------------------- //

    suggestHousing = (applicationid: string) => {
        return this.http.get(`${environment.API}/applications/${applicationid}/housing?:vendor`);
    };
    // ------------------------------------------------------------------------------------------------------------- //

    requestEnrollment = (applicationid: string): Observable<any> => {
        return this.http.get(`${environment.API}/applications/${applicationid}/requestenrollment`);
    };
    // ------------------------------------------------------------------------------------------------------------- //

    chainedRequestEnrollment = (applicationchainid: string): Observable<any> => {
        return this.http.get(`${environment.API}/applications/${applicationchainid}/chainedrequestenrollment`);
    };

    // ------------------------------------------------------------------------------------------------------------- //

    reviewApplication = (applicationid: string, review: any): Observable<any> => {
        return this.http.post(`${environment.API}/applications/${applicationid}/review`, review);
    };

    // ------------------------------------------------------------------------------------------------------------- //

    /**
     * @params applicationid completed application id
     * @returns the review questions for that application
     *
     */
    showReviewQuestions = (applicationid: string) => {
        return this.http.get(`${environment.API}/reviewtemplate/${applicationid}/show`);
        // var hardCodedQuestions = [
        //   {
        //     "sortorder": 1,
        //     "type": "string",
        //     "question": "<p>* 1. Clinical Skills - Please list the clinical skills you learned and participated in during your rotation (i.e. techniques, procedures)</p>"
        //   },
        //   {
        //     "sortorder": 2,
        //     "type": "boolean",
        //     "question": "<p>* 2. Would you recommend AMOpportunities to a friend?</p>"
        //   },
        //   {
        //     "sortorder": 3,
        //     "type": "number",
        //     "question": "<p>* 3. Please indicate your level of satisfaction with the AMO team and resources provided.</p>"
        //   }
        // ];

        // return hardCodedQuestions;
    };

    // ------------------------------------------------------------------------------------------------------------- //
    /**
     * @param applicationid completed  application id
     * @param userid user who completed this application
     * @returns certificate of completion
     */
    downloadCertificationOfCompletion = (applicationid: string, userid: string): Observable<any> => {
        return this.http.get(`${environment.API}/applications/${applicationid}/viewcertificateofcompletion?userid=${userid}`);
    };
    // ------------------------------------------------------------------------------------------------------------- //

    // ------------------------------------------------------------------------------------------------------------- //

    requestBooking = (applicationid: string) => {
        const body = { info: 'some info' };
        return this.http.post(`${environment.API}/applications/${applicationid}/acceptRequest`, body);
    };

    // -----------------------------------------------------------------------------------------------------//

    processClinicalAgreement = (applicationid: string, userid: string, programid: string) => {
        return this.http.get(`${environment.API}/applications/
    ${applicationid}/processclinicalagreement?userid=${userid}&programid=${programid}`);
    };

    // ------------------------------------------------------------------------------------------------------- //
    helloSign = (id: string) => {
        return this.http.get(`${environment.API}/applications/hellosign?applicationId=${id}`);
    };

    helloSignProccessApplication = (data: any) => {
        const stringData = JSON.stringify(data);
        return this.http.get(`${environment.API}/applications/hellosignapplicationprocess?d=${btoa(stringData)}`);
    };

    // ------------------------------------------------------------------------------------------------------------------//

    requestInvitation(applicationid: string): Observable<any> {
        return this.http.get(`${environment.API}/applications/${applicationid}/requestinvitation`);
    }

    /**
     * @description takes an application and returns the correct deadline message for UI components
     */
    applicationDeadlineCalculator = (application: any): any => {
        // application deadline is making the first payment
        // get days until late
        const diffInDays = application.paymentDeadlineDate.days_until_late;
        // program record deadlinedays value
        const customDeadlineDays: number = application.paymentDeadlineDate.program_deadlinedays;
        // create custom UI message
        let customDeadlineMessage;
        // set deays until late message
        if (diffInDays >= 45) {
            customDeadlineMessage = `The deadline to enroll is ${customDeadlineDays} days before your scheduled start date.
            Failure to complete your payment balance before the deadline will result in a $200 late fee.`;
        } else if (diffInDays >= 30) {
            customDeadlineMessage = `Please complete your required documents as soon as possible in order
            to avoid any complications in your travel arrangements or with the start of your rotation.`;
        } else {
            customDeadlineMessage = `Please complete required documents as soon as possible.
            Failure to do so may result in your program being delayed or cancelled.`;
        }
        return { message: customDeadlineMessage };
    };

    viewadditionalrequirement(applicationid, userpacket): Observable<any> {
        return this.http.post(`${environment.API}/applications/${applicationid}/viewadditionalrequirement`, userpacket);
    }

    downloadRequirementDocument(body: any): Observable<any> {
        return this.http.post(`${environment.API}/applications/usergetapplicationrequirementdocumentsignedurl`, body);
    }

    /* Reminders per deadline date prior to program start date:

45-day reminder:
"The deadline to enroll is 45 days before your scheduled start date.
Failure to complete your payment balance before the deadline will result in a $200 late fee."

30-day reminder:
"Please complete your required documents as soon as possible in order
to avoid any complications in your travel arrangements or with the start of your rotation."

15-day reminder:
"Please complete required documents as soon as possible. Failure to do so may result in your program being delayed or cancelled."

*/

    /**
     * @description returns a bool that sets the bookReq state
     */
    bookReqCheck = (user: any): boolean => {
        if (!validCv(user.item1name) || isNil(user.item4name)) {
            return false;
        } else {
            return true;
        }
    };

    /**
     * @description returns JSON array of application review questions
     * @param applicationid: string value of application id to receive review questions
     * @memberof ApplicationService
     */
    showReviewsQuestions = (applicationid: string, reviewType: string): Observable<any> => {
        return this.http.get(`${environment.API}/reviews/${applicationid}/showreviewquestions?type=` + reviewType);
    };

    applicationStateCalculator(applicationState: string, enddate: string, expires: string): string {
        return (isAfter(new Date(), new Date(enddate)) || isAfter(new Date(), new Date(expires))) && applicationState === 'Enrolled'
            ? 'SubmitReview'
            : applicationState;
    }
    /**
     * @description
     * @param applicationid: id for single application
     *
     * @memberof ApplicationService
     */
    requestPreAcceptanceReview = (applicationid: any): any => {
        return this.http.get(`${environment.API}/applications/${applicationid}/requestpreacceptancereview`);
    };

    /**
     * @description
     * @param applicationchainid: id for application chain
     *
     * @memberof ApplicationService
     */
    requestPreAcceptanceReviewChain = (applicationchainid: any): any => {
        return this.http.get(`${environment.API}/applications/${applicationchainid}/requestpreacceptancereviewchain`);
    };

    helloSignServerless = (data: any): Observable<any> => {
        return this.http.post(environment.CA_HS_SERVERLESS, data);
    };

    promptForMidRotationReview = (applicationid: any): any => {
        return this.http.get(`${environment.API}/applications/promptmidrotationreview?applicationid=` + applicationid);
    };

    /**
     * @description save location of requirement uploads to application table
     * @param applicationid string
     * @param applicationidList array of application id
     * @param applicationrequirementid requirement id of the upload
     * @param xmlObject formatted xml of the upload
     */
    updateSubmittedRequirement = (
        applicationid: string,
        applicationidList: string[],
        applicationrequirementid: string,
        xmlObject: any,
        acknowledgeObject: { submittedValues: {} }
    ): Observable<any> => {
        // check if xmlObject or dataType acknowledgement is empty
        const body = {
            trace: xmlObject ? { ...xmlObject } : { ...acknowledgeObject },
            applicationId: applicationid,
            applicationIdList: [...applicationidList],
            applicationrequirementid,
        };

        return this.http.post(`${environment.API}/applications/updatesubmittedrequirements`, body);
    };

    //*******************************************************************************************************/
    //************************************ APPLICATION CHAIN ENDPOINTS *************************************/
    //*******************************************************************************************************/

    /**
     *
     * @description return list of applicant's chained applications
     * @memberof ApplicationService
     */
    listuserapplicationchains = (): Observable<any> => {
        return this.http.get(`${environment.API}/applicationchains/listuserapplicationchains`);
    };

    /**
     * @memberof ApplicationService
     * @description return list of user application chained applications total
     * @param id user id
     */
    usershowapplicationchain = (id): Observable<any> => {
        return this.http.get(`${environment.API}/applicationchains/${id}/usershowapplicationchain`);
    };

    newItinerary = (body: any): Observable<any> => {
        return this.http.post(`${environment.API}/applicationchains/usercreateapplicationchain?ngsw-bypass=true`, body);
    };

    userValidateItinerarySelection = (body: any): Observable<any> => {
        return this.http.post(`${environment.API}/applicationchains/uservalidateitineraryselection?ngsw-bypass=true`, body);
    };

    usersCurrentItinerary = (body: any): Observable<any> => {
        return this.http.post(`${environment.API}/itineraries/userscurrentitinerary?ngsw-bypass=true`, body);
    };

    updateUserItinerarySelections = (body: any): Observable<any> => {
        return this.http.post(`${environment.API}/itineraries/updateuseritineraryselections?ngsw-bypass=true`, body);
    };

    /**
     *
     * @param documentKey
     */
    userViewdocument(documentKey: any, userid: any): Observable<any> {
        return this.http.get(`${environment.API}/users/${userid}/userdocumentview/?key=${documentKey}`);
    }
}
